import "./App.css";
import WelcomePage from "./pages/welcome";

function App() {
    return (
        <div className="App">
            <WelcomePage />
        </div>
    );
}

export default App;
