import "./welcome.css";

function WelcomePage() {
    const isWindows = navigator.platform.toUpperCase().indexOf("WIN") >= 0;

    return (
        <div className="w-full">
            {/* Head Section */}
            <div className="heading flex justify-center border-b-8">
                <div className="w-full max-w-screen-xl">
                    <div className="relative">
                        <img
                            src="/assets/imgs/chow_bg.svg"
                            alt=""
                            className="max-h-96 md:max-h-screen opacity-50 sm:opacity-100 md:w-1/2 z-0"
                        ></img>
                        <div className="absolute right-8 top-8">
                            <p className="head-state font-Inter text-xl font-bold text-white">
                                JOIN OUR COMMUNITY
                            </p>
                            <div className="flex justify-end mt-4">
                                <a
                                    href="https://twitter.com/chowchowdegen"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="w-7 h-7 sm:w-8 sm:h-8 mr-4"
                                        src="/assets/imgs/x_icon.svg"
                                        alt=""
                                    ></img>
                                </a>
                                <a
                                    href="https://t.me/chowchowofficialannouncements"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="w-7 h-7 sm:w-8 sm:h-8"
                                        src="/assets/imgs/tg_icon.svg"
                                        alt=""
                                    ></img>
                                </a>
                            </div>
                        </div>

                        <div className="hidden sm:block sm:absolute sm:top-24 lg:top-32 sm:right-auto sm:left-1/2 lg:-mt-2 xl:mt-0 -ml-20 xl:-ml-24">
                            <img
                                src="/assets/imgs/cc.png"
                                alt=""
                                className="h-48 md:h-56 lg:h-72 xl:h-80"
                            ></img>
                            <div className="flex justify-end w-full">
                                <img
                                    src="/assets/imgs/$CHOW.svg"
                                    alt=""
                                    className="-mr-10 -mt-2 h-10 lg:h-16"
                                ></img>
                            </div>
                        </div>

                        <div className="absolute sm:hidden top-0 left-0 w-full h-full">
                            <div className="flex justify-center items-center w-full h-full flex-col pt-10">
                                <img
                                    src="/assets/imgs/cc.png"
                                    alt=""
                                    className="h-40"
                                ></img>
                                <img
                                    src="/assets/imgs/$CHOW.svg"
                                    alt=""
                                    className="ml-52 h-10"
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* JOIN Description Section */}
            <div className="w-full -mt-12 md:-mt-16 flex justify-center z-10">
                <div className="w-full max-w-screen-xl flex flex-col items-center">
                    <a
                        className="description-out-box rounded-xl sm:rounded-2xl p-1 sm:p-1.5 md:p-2 z-20"
                        href="https://www.dextools.io/app/en/bnb/pair-explorer/0xecd6abb254407c62ffd3e5b068fef6e4544bd816"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="join-box w-full rounded-lg sm:rounded-xl text-white h-12 md:h-16 px-4 md:px-8 hover:bg-white hover:text-black hover:transition-all flex justify-center items-center">
                            {!isWindows ? (
                                <p className="text-2xl md:text-4xl mac-join">
                                    EXPLORE ON DEXTOOLS
                                </p>
                            ) : (
                                <p className="text-2xl md:text-4xl">
                                    EXPLORE ON DEXTOOLS
                                </p>
                            )}
                        </button>
                    </a>
                    <div className="description-out-box w-full sm:w-5/6 max-w-3xl z-10 rounded-3xl p-2 sm:p-3 md:p-4 -mt-8 sm:-mt-10 md:-mt-12">
                        <div className="w-full rounded-2xl bg-white h-full px-8 pt-10 pb-8 sm:px-10 sm:pt-14 sm:pb-10 md:px-16 md:pt-20 md:pb-16">
                            <p className="blue-color text-xl md:text-2xl font-bold text-left">
                                Yas, another one. Because it’s the greatest Asian canine of all times. Full of meme, zero utility, degen style only. What’s not to love?
                            </p>
                            <br></br>
                            <p className="text-left md:text-xl font-bold">
                                Devs created a tiny Chow Chow ($CHOW) liquidity pool. Let us all pamp it for CEX listings, marketing and (if possible) a big Summer party in Amsterdam! LFG.
                            </p>
                            <br></br>
                            <p className="text-left md:text-xl">
                                Why? Emphasising loyalty, charm, and the breed's
                                distinct appearance to create a relatable
                                connection and embrace a meme culture that
                                revolves around Chow Chows.
                            </p>
                            <br></br>
                            <p className="text-left md:text-xl">
                                <span className="font-bold text-black">
                                    100.000.000.000 $CHOW, 90% community owned (with a 2% airdrop!) ~ 7% for listings, 3% treasury.
                                </span>{" "}
                                <br></br>
                                <br></br>
                                Fair enough? No banks, no taxes, no insiders, no
                                rigs. Only fame, fluff and fairness. Liquidity
                                locked, contract renounced.
                            </p>
                            <br></br>
                            <p className="text-left md:text-xl">
                                Woof. Are you in?
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="w-full flex flex-col items-center mb-16">
                <div className="w-full flex justify-center">
                    <a
                        href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xBAda1eD4003fEE20075b44B2d3B9dB7Bae472042&chain=bsc"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src="/assets/imgs/pancake.svg"
                            className="w-40 md:w-72"
                            alt=""
                        ></img>
                    </a>
                    <a
                        href="https://www.bnbchain.org/en/bnb-smart-chain"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src="/assets/imgs/bsc.png"
                            className="w-40 md:w-72"
                            alt=""
                        ></img>
                    </a>
                </div>
                <p className="footer-text opacity-50 text-sm px-3">
                    We’re serious about making this happen. Built on Binance Smart Chain.
                    Made with Woof, for Degens. 
                    <br></br>
                    <br></br>
                    Official contract address: 0xbada1ed4003fee20075b44b2d3b9db7bae472042 (BEP-20). Official contact email: info@chowchow.site
                </p>
            </div>
        </div>
    );
}

export default WelcomePage;
